import { stageMap as stages } from './stages'

// ADMIN: Input the stages for each treatment.
// Note: order and exact spelling matter!
const protocol = [
  stages.introduction,
  stages.initialRead,
  stages.initialDiscussion,
  stages.initialDecision,
  stages.deliverIntervention,
  stages.profileRecollection,
  stages.postInterventionDecision,
  stages.feedback,
  stages.goodbye,
]
const newLanguage = [
  stages.introduction,
  stages.initialRead,
  stages.initialDiscussion,
  stages.explainCriteria,
  stages.criteriaRecollection,
  stages.feedback,
  stages.goodbye,
]
const discussion = [
  stages.introduction,
  stages.initialRead,
  stages.initialDiscussion,
  stages.initialDecision,
  stages.feelings,
  stages.aboutHP,
  stages.fullProfile,
  stages.feedback,
  stages.conversationMetrics,
  stages.postInterventionDiscussion,
  stages.postInterventionDecision,
  stages.goodbye,
]
const followup = [
  stages.introduction,
  stages.initialRead,
  stages.initialDiscussion,
  stages.initialDecision,
  stages.feelings,
  stages.fullProfile,
  stages.goodbye,
]
const infoOnly = [
  stages.introduction,
  stages.aboutHP,
  stages.strategies,
  stages.goodbye,
]
const tag = [
  stages.introduction,
  stages.tagGame,
  stages.tagFeedback,
  stages.goodbye,
]
const tagSingleplayerGameQuestion = [
  stages.introduction,
  stages.tagGame,
  stages.longTextQuestionsForm,
  stages.goodbye,
]
const tagSingleplayerGameQuestionFeedback = [
  stages.introduction,
  stages.tagGame,
  stages.longTextQuestionsForm,
  stages.dynamicTextFeedback,
  stages.goodbye,
]
const tagSingleplayerQuestionGameChecklist = [
  stages.introduction,
  stages.longTextQuestionsForm,
  stages.tagGame,
  stages.selection,
  stages.goodbye,
]
const tagSingleplayerGameChecklistQuestion = [
  stages.introduction,
  stages.tagGame,
  stages.selection,
  stages.longTextQuestionsForm,
  stages.goodbye,
]
const tagSingleplayerGameChecklistQuestionFeedback = [
  stages.introduction,
  stages.tagGame,
  stages.selection,
  stages.longTextQuestionsForm,
  stages.dynamicTextFeedback,
  stages.goodbye,
]
const tagMultiplayer = [
  stages.introduction,
  stages.tagGame,
  stages.longTextQuestionsForm,
  stages.postTagDiscussion,
  stages.discussionRecollection,
  stages.goodbye,
]
const tagMultiplayerDiscussionFirst = [
  stages.introduction,
  stages.tagGame,
  stages.postTagDiscussion,
  stages.longTextQuestionsForm,
  stages.decisionRecollection,
  stages.goodbye,
]

export default {
  tag,
  tagSingleplayerGameQuestion,
  tagSingleplayerGameQuestionFeedback,
  tagSingleplayerQuestionGameChecklist,
  tagSingleplayerGameChecklistQuestion,
  tagSingleplayerGameChecklistQuestionFeedback,
  tagMultiplayer,
  tagMultiplayerDiscussionFirst,
  discussion,
  followup,
  infoOnly,
  newLanguage,
  protocol,
}
